<template>
  <div>
    <el-row class="main">
      <el-col>
        <div class="header">
          <div class="content">
            <div class="header-content">
              <div class="title">
                <!-- <img src="../../assets/portal/logo.png" alt="" />  -->
                榆阳人力资源网
              </div>
              <div class="title-navigation">
                <!-- <div class="navigation-btn">
                  <img src="../../assets/portal/index.png" alt="" />
                  <span>首页</span>
                </div> -->
                <div class="navigation-btn" @click="$router.push('/portalPart/personalInformation')">
                  <el-avatar :size="20" :src="state.image1Url" />
                  <span style="margin-left: 0">{{
                    store.state.user.userId
                  }}</span>
                </div>
                <div class="navigation-btn" @click="logout">
                  <img style="margin-bottom: -0.0313rem" src="../../assets/portal/exit.png" alt="" />
                  <span>退出</span>
                </div>
              </div>
            </div>
            <div class="header-title">
              <!-- <div style="width: 100%; text-align: center">
                <div class="subtitle">2022人力资源管理师</div>
              </div> -->
              <div class="main-title">
                榆林市榆阳区人力资源报名服务系统<span style="color: #ffe11a"></span>
              </div>
              <div style="width: 100%; text-align: center">
                <!-- <div class="subtitle" style="padding: 0 0.0521rem">
                  <span>政策变革</span>
                  <span>难度加大</span>
                  <span>人数增加</span>
                  <span>预占考位</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="portal">
          <div class="content">
            <div class="portal-title">快捷入口</div>
            <div class="portal-main">
              <div class="portal-btn" @click="$router.push('/portalPart/systemAnnouncement')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/SystemAnnouncement.png" alt="" />
                </div>
                <span>系统公告</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/candidateEntrance')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/CandidateEntrance.png" alt="" />
                </div>
                <span>考生入口</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/examinationPermitPrinting')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/printer.png" alt="" />
                </div>
                <span>准考证打印</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/messageNotification')">
                <div v-if="pageStatus.messageNum" class="titleBox">
                  {{ pageStatus.messageNum }}
                </div>
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/message.png" alt="" />
                </div>
                <span>消息通知</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/scoreQuery')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/grades.png" alt="" />
                </div>
                <span>成绩查询</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/myRegistration')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/examination.png" alt="" />
                </div>
                <span>我的报考</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/personalInformation')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/userInfo.png" alt="" />
                </div>
                <span>个人信息</span>
              </div>
              <div class="portal-btn" @click="$router.push('/portalPart/accountSecurity')">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/safe.png" alt="" />
                </div>
                <span>账户安全</span>
              </div>
              <!-- <div class="portal-btn">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/help.png" alt="" />
                </div>
                <span>帮助中心</span>
              </div>
              <div class="portal-btn">
                <div style="padding-bottom: 0.0781rem">
                  <img src="../../assets/portal/consultingService.png" alt="" />
                </div>
                <span>在线咨询</span>
              </div> -->
            </div>
          </div>
        </div>
        <Footer></Footer>
      </el-col>
    </el-row>
  </div>
</template>
  
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { useStore } from "vuex";
let router = useRouter();
let store = useStore();
onMounted(async () => {
  getMessageList()
  // store.state.user.userId = window.sessionStorage.getItem("userId")
  // state.image1Url = window.sessionStorage.getItem("img")

  if (store.state.user.userInfo.photo) {
    state.image1Url = 'data:image/jpg;base64,' + store.state.user.userInfo.photo
  } else {
    getInfo()
  }

});
let pageStatus = reactive({
  messageNum: 0
})
// 获取所有消息通知
const getMessageList = async () => {
  let res = await get("/examApply/messageList", { pageSize: 999, pageNo: 1 });
  if (res.code === 200) {
    let num = window.localStorage.getItem('messageNum')
    if (num && num < res.result.total) {
      pageStatus.messageNum = res.result.total - num
    }
  }
};
let state = reactive({ image1Url: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' });
let logout = () => {
  ElMessageBox.confirm("确认退出当前用户吗?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      store.commit("user/logout");
    })
    .catch(() => { });
};
// 获取个人信息
function getInfo () {
  get("/examApply/user/getInfo").then(res => {
    store.commit('user/getUserinfo', res.result)
    // state.image1Url = 'data:image/jpg;base64,' + store.state.user.userInfo.photo
    // state.image1Url = 'data:image/jpg;base64,' + res.result.photo
    // window.sessionStorage.setItem("img", 'data:image/jpg;base64,' + res.result.photo)
    // state.image1Url = window.sessionStorage.getItem("img")
  })
}
</script>
  
<style lang="scss" scoped>
.main {
  background-color: #f2f4f9;
  height: 100vh;
  overflow: hidden;
}

.content {
  padding: 0 18%;
  font-size: 0.0833rem;
}

.header {
  // height: 412px;
  background-color: skyblue;
  background: url(../../assets/portal/portal-header.jpg) no-repeat;
  background-size: 100%;

  .content {
    min-height: 36vh;
  }

  .header-content {
    height: 0.4167rem;
    line-height: 0.4167rem;
    margin-bottom: 0.0781rem;

    .title {
      float: left;
      font-size: 0.1563rem;
      font-family: PingFang SC;
      font-weight: 800;
      color: #ffffff;
      cursor: default;

      img {
        width: 0.1563rem;
        vertical-align: middle;
        margin-top: -0.0208rem;
      }
    }

    .title-navigation {
      float: right;

      .navigation-btn {
        display: inline-block;
        min-width: 0.4688rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.0833rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;

        img {
          margin-bottom: -0.0104rem;
        }

        span {
          margin: 0 0.026rem;
          vertical-align: middle;
        }
      }

      .navigation-btn:hover {
        color: #e8c207;
      }
    }
  }

  .header-title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    .subtitle {
      display: inline-block;
      text-align: center;
      padding: 0 0.0521rem;
      height: 0.1875rem;
      line-height: 0.1875rem;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.0938rem;
      font-size: 0.1042rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: default;

      span {
        display: inline-block;
        padding: 0 0.0781rem;
      }
    }

    .main-title {
      text-align: center;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #ffffff;
      margin: 0.1563rem 0;
      cursor: default;
    }
  }
}

.portal {
  .content {
    min-height: 59vh;
  }

  .portal-title {
    height: 0.3646rem;
    line-height: 0.3646rem;
    text-align: center;
    font-size: 0.1563rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
  }

  .portal-main {
    display: flex;
    flex-wrap: wrap;
    height: 2.5rem;

    .portal-btn {
      position: relative;
      box-sizing: border-box;
      padding-top: 0.2604rem;
      width: 23%;
      margin-right: 2.5%;
      height: 1.1458rem;
      background-color: #ffffff;
      cursor: pointer;
      text-align: center;
      margin-bottom: 0.0521rem;

      span {
        font-size: 0.1042rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 0.25rem;
      }

      .titleBox {
        position: absolute;
        top: -0.0521rem;
        right: -0.0521rem;
        font-size: 24px;
        width: 0.1563rem;
        height: 0.1563rem;
        line-height: 0.1563rem;
        text-align: center;
        background-color: #eb1818;
        color: #ffffff;
        border-radius: 50%;
      }

      img {
        height: 50px;
      }
    }

    .portal-btn:nth-child(4),
    .portal-btn:nth-child(8) {
      margin-right: 0;
    }

    .portal-btn:hover {
      background-color: #eee;
    }
  }
}
</style>